<template>
  <div class="property-updates-wrapper">
    <Permission/>
    <Breadcrumb/>
    <div class="level is-align-items-flex-start">
      <div class="level-left">
        <div>
          <div class="title">Property Updates</div>
          <div class="subtitle" v-html="propertyName || '&nbsp;'"></div>
        </div>
      </div>
      <div v-if="canCreate" class="level-right">
        <b-button
          @click="toNew"
          class="is-blue has-text-white is-small-height has-text-weight-semibold"
          >Add New Update</b-button>
      </div>
    </div>
    <Tabs/>
    <b-table :data="propertyUpdates" hoverable>
      <b-table-column field="date" label="Date" v-slot="props">
        <a @click="toUpdate(props.row.id)">{{ props.row.updateDate }}</a>
      </b-table-column>
      <b-table-column field="type" label="Type" v-slot="props">{{ props.row.updateType }}</b-table-column>
      <b-table-column field="text" label="Message" v-slot="props">{{ props.row.updateText }}</b-table-column>
      <b-table-column field="cover_photo" label="Message" v-slot="props">
        <figure class="image is-128x128 cover-photo" :style="{ backgroundImage: 'url(' + props.row.updateCoverPhotoUrl + ')' }"></figure>
      </b-table-column>
    </b-table>
        <b-pagination
            :total="pagination.total"
            v-model="pagination.currentPage"
            range-before="3"
            range-after="3"
            :per-page="pagination.perPage"
            disabled="true"
            @change="fetchUpdates"
            >
        </b-pagination>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Tabs from './../Tabs.vue'
import { fetchPropertyUpdates } from '@api/addy-plus/property-updates'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'Property Updates',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Tabs,
    Breadcrumb,
  },
  data() {
    return {
      propertyUpdates: [],
      pagination: {
        total: 0,
        currentPage: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    propertyId() {
      return this.$route.params.propertyId
    },
    propertyName() {
      return this.$store.state.addyPlusBase.corporationsForSideMenu[this.corporationId]?.properties?.find((prop) => prop.id === this.propertyId)?.name
    },
    canCreate() {
      return this.permissions.assetUpdates?.includes('create')
    },
  },
  mounted() {
    this.fetchUpdates()
  },
  methods: {
    fetchUpdates() {
      fetchPropertyUpdates(this.propertyId, this.pagination.currentPage, this.pagination.perPage).then((res) => {
        if (!res.success) return
        this.propertyUpdates = res.data.propertyUpdates
        this.pagination.total = res.data.pagination.total
      })
    },
    toUpdate(updateId) {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/properties/${this.propertyId}/updates/${updateId}`)
    },
    toNew() {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/properties/${this.propertyId}/updates/new`)
    },
  },
}
</script>
<style lang="scss" scoped>
.property-updates-wrapper {
  font-size: 16px;
  .image.cover-photo {
    background-size: cover;
    background-position: 50% 50%;
  }
}
</style>
